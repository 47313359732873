import React from "react"
import { graphql } from "gatsby"
import Navbar from "../../components/NavBar"
import Carousel from "../../components/Carousel"
import Footer from "../../components/Footer"
import Metatags from "../../components/metatags"
import UseSiteMetadata from "../../components/UseSiteMetadata"

const daucodesignsPT = ({ pageContext, data, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "pt-BR",

      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | daucodesigns"
        description="website para daucodesigns"
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <Navbar />
      <div className="h-10" />
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <p>daucodesigns</p>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-6">
            <button
              className="btn-icon rounded text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-800 px-3 py-1.5"
              href="https://daucodesigns.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              daucodesigns.com
            </button>
            <div className="h-5" />
            <p>
              Daucodesigns é uma empresa de mobiliário home & contract com sedes
              em Madrid e Barcelona.
            </p>
            <p>
              O principal objetivo do website é atrair clientes do segmento
              através de uma grande quantidade de imagens de móveis das
              principais marcas comercializadas.
            </p>
            <p>
              Por solicitação do cliente desenvolvemos um carrossel
              personalizado com imagens em tela cheia com peças individuais bem
              como ambientes em que são utilizadas.
            </p>
          </div>
        </div>
        <div className="h-8" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">desenvolvimento</div>
          <div className="col-span-4 tec-feature">gatsby js</div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">integrações</div>
          <div className="col-span-4 tec-feature">
            architonic - plataforma de arquitetura e design
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-4" />
          <div className="col-span-2">implantação</div>
          <div className="col-span-4 tec-feature">aws S3 em cloudfront</div>
        </div>
        <div className="h-10" />
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel images={data.portfolioImages.edges} alt="daucodesigns" />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer lang="pt" />
    </>
  )
}

export default daucodesignsPT

export const query = graphql`
  {
    portfolioImages: allFile(
      filter: { relativePath: { regex: "/webs/daucodesigns/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1280)
          }
        }
      }
    }
  }
`
